import styled from "@emotion/styled";
import Typography from "../../components/Typography";
import colors from "../../styles/colors";

export const TextContent = styled.div`
  max-width: 700px;

  p {
    font-size: 1.25rem;
    line-height: 1.7;
  }
`;

export const Title = styled(Typography)`
  margin-bottom: 1.5rem;
`;

export const Description = styled(Typography)`
  margin-bottom: 2.5rem;
  color: ${colors.grey};
`;

export const CreatorDate = styled(Typography)`
  color: ${colors.grey};
  text-align: center;
`;

export const HeaderImg = styled.img`
  margin: 4rem 0rem;
  border-radius: 1rem;
`;

export const InnerSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
