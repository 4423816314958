import styled from "@emotion/styled";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";
import SketchImage from "../../images/sketch-underline.svg";

export const Wrapper = styled.div`
  background-color: ${colors.greyBackground};
  flex-direction: column;
  padding: 7rem;
  border-radius: 1rem;
  text-align: center;
  flex: 1;

  @media (max-width: ${breakpoints.large}) {
    padding: 5rem;
  }

  @media (max-width: ${breakpoints.small}) {
    padding: 3rem;
  }
`;

export const Message = styled.div`
  align-items: center;
  margin-bottom: 3rem;
`;

export const InputRow = styled.div`
  padding-bottom: 0;
`;

export const SketchUnderline = styled.span`
  background: url(${SketchImage}) bottom left no-repeat;
  background-size: 100% 1rem;
  padding-bottom: 0.5rem;
`;
