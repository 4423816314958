/*
 * The routes are generated dynamically from gatsby-node.js
 */
import React from "react";
import { graphql } from "gatsby";
import Section from "../../components/Section";
import SEO from "../../components/SEO";
import CTA from "../../components/CTA";
import {
  CreatorDate,
  Description,
  Title,
  HeaderImg,
  InnerSection,
  TextContent,
} from "./styles";

const Post = (props) => {
  const {
    data: {
      prismicPost: {
        data: {
          author: { text: author },
          content: { html: content },
          created_at: createdAt,
          description: { text: description },
          header: { url: headerSrc },
          title: { text: title },
        },
      },
    },
  } = props;
  let creatorDate = createdAt;
  creatorDate = author ? `${author}, ${creatorDate}` : creatorDate;

  return (
    <div>
      <Section shrinkTop>
        <InnerSection>
          <SEO title={title} image={headerSrc} />
          <TextContent>
            <Title variant={"h1"} textAlign={"center"}>
              {title}
            </Title>
            <Description variant={"body"} textAlign={"center"}>
              {description}
            </Description>
            <CreatorDate variant={"body"}>{creatorDate}</CreatorDate>
          </TextContent>
          <HeaderImg src={headerSrc} />
          <TextContent dangerouslySetInnerHTML={{ __html: content }} />
        </InnerSection>
      </Section>
      <CTA />
    </div>
  );
};

export const pageQuery = graphql`
  query PostBySlug($uid: String!) {
    prismicPost(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        content {
          html
        }
        description {
          text
        }
        header {
          url
        }
        author {
          text
        }
        created_at(formatString: "MMMM Do, YYYY")
      }
    }
  }
`;

export default Post;
