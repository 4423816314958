import React from "react";
import { Message, InputRow, SketchUnderline, Wrapper } from "./styles";
import Typography from "../Typography";
import Button from "../Button";
import { navigate } from "gatsby";
import Section from "../../components/Section";

const CTA = ({ location }) => {
  return (
    <Section>
      <Wrapper>
        <Message>
          <Typography variant="h2">
            Say hello to your <SketchUnderline>new workflow</SketchUnderline>
          </Typography>
        </Message>
        <InputRow>
          <Button onClick={() => navigate("/demo")} variant="primary">
            Get started
          </Button>
        </InputRow>
      </Wrapper>
    </Section>
  );
};

export default CTA;
